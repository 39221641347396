import * as React from "react"
import Layout from '../components/Layout'

const NewsPage = () => {
  return (
    <Layout>
      <h1>News page</h1>
    </Layout>
  )
}

export default NewsPage
